<template>
    <v-main>
        <Header></Header>
        <div class="section9">
        
        <!-- <v-row class="d-none d-md-flex d-lg-flex d-xl-flex"> -->
                 <v-container class="justify-center">
            
        <!-- <v-row > -->
                <!-- <v-col>
                    <div class="space">

        <v-img height="22em" :src="require('../assets/fish Website Pictures/edho-pratama-yeB9jDmHm6M-unsplash.jpg')"> -->
         <v-card-title class="justify-center"   style="margin:5em 0px 5em 0px; word-break: normal;"  primary-title>
             <h2>
                Menasave
             </h2>
         </v-card-title>
        </v-container>
             <v-container >
                 <div class="space" >

            <v-card  hover outlined raised color="rgba(139, 214, 255,0.2)">
                <v-row>
                  <v-col>
                      <v-card-title class="justify-center" >    
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/location.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center">
                      Riyadh, KSA
                      </v-card-title>
                          
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                        
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/Category.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center" >
                          
                          Web Development, Backend API Maintenance, and Software Architecture Consultancy
                      </v-card-title>
                     
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                         <v-img height="3em" width="4em" contain :src="require('../assets/icons/industry.svg')"></v-img>
                      </v-card-title>
                         <v-card-title style="word-break:normal" class="justify-center">
                            Goods & Services Retail E-Commerce
                         </v-card-title>
                  </v-col>
                </v-row>
            </v-card>
                 </div>
              </v-container>
            <!-- </v-row> -->
            
        <v-container>
        <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="3" md="4" sm="12"   >
                 <v-container >
                <div class="space" >
                    <v-img contain max-height="15em" max-width="15em" :src="require('../assets/portfolio/Transparent Logo.webp')">
                    
                    </v-img>
                </div>
                  </v-container>
            </v-col>

                





            <v-col>
                <v-container>    
                <v-row>
                <h2>About Mena Save</h2> 
                <div class="space">
                    <v-col>
                    <p>
                        Menasave is a project that was built for Sudanese expatriates. To ensure the continuity of interdependence and social solidarity, and to facilitate the delivery of aid to those who are inside Sudan.

                        By giving the means to buy the needs of families and friends through a set of online services with electronic payment methods.
                    </p>

                    </v-col>
                </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
                    
        <v-row>
            <v-col >
                <h2>
                    What The Code Effect Did:
                </h2>
                <div class="space">
                    <v-container>
                        

                <p>
                    Menasave started as an E-Commerce Mobile Application. With an android native interface developed by java(Android). 

                     The Code Effect reshaped the Architecture of the API and the deployment of the application, switching from classical OOP to Microservices Architecture
                      and introducing CI/CD concepts to the deployment. 
                     <br>
                     We also collaborated with  Mr.Tag Eldeen (Mobile Aplication Engineer) on this project. Where He developed the new mobile Flutter Application for both iOs and Android,
                      and we helped through the integration and maintenance of the API with the new Mobile App.
                     <br>
                     The Code Effect also designed and developed Menasave Web Application, giving the company the new online presence that it needed. 
                 
                </p>
                    </v-container>
    </div>
            </v-col>
        </v-row>
         <v-container >
            <v-row>
              <v-col lg="6" md="12" sm="12">
                      <MockUp/>
              </v-col>
              <v-col lg="6" md="12" sm="12">
                  <iOsMockup></iOsMockup>
              </v-col>
            </v-row>
    </v-container>

          </v-container>
           
            <!-- <v-row>
                <v-col>

            <h2 class="ma-10">
                Mr.Alameen (Mena Save CEO) said about us:
            </h2>
        <div class="aurora">
            <v-container>
                
            <p>
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt minima, similique quisquam sint maiores fugit, velit repellendus at dolor nostrum laudantium laborum reiciendis sequi iure. Perferendis impedit facere deleniti ullam?"
            </p>
            </v-container>
        </div>
                </v-col>
            </v-row> -->
          </v-container>
          
        <Footer></Footer>
        </div>

    </v-main>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer'
import MockUp from '../components/MockUp'
import IOsMockup from '../components/iOsMockup.vue'

export default {
    name: 'Menasave',
    components:{
        Header,
        MockUp,
        Footer,
        IOsMockup 
    },
    
 

}
</script>
